<template>
  <div class="common-container">
    <div class="left" @click="$emit('moreAction')">
      <p class="title">{{ title }}({{ total }})</p>
      <p class="introduction ">
        <span class="ellipsis-line4">{{ introduction }}</span>
      </p>
      <button class="button">查看更多</button>
    </div>
    <div class="right" :class="notFull ? 'not-full' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'total', 'introduction', 'notFull'],
  name: 'CommonContainer'
}
</script>

<style lang="scss" scoped>
.common-container {
  display: flex;
  height: 100%;
  width: 100%;

  .left {
    width: 150px !important;
    display: flex;
    flex-direction: column;
    background: #fafbff;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    .title {
      height: 60px;
      background: url('/img/编组 35.png') -1px 0px no-repeat;
      background-size: 150px 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }

    .introduction {
      flex: 1;
      padding: 20px;
      cursor: pointer;

      > span {
        display: block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
        max-height: 150px;
      }
    }

    .button {
      width: 95px;
      height: 30px;
      line-height: 30px;
      border: none;
      border-radius: 15px;
      color: #ffffff;
      background: #5ad3cd;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin: 0 auto;
      margin-bottom: 20px;
      cursor: pointer;
      text-align: center;
    }
  }

  .right {
    margin-right: 0 !important;
    flex: 2;
    display: flex;

    > div {
      margin-left: 16px;
    }
  }
}
</style>
