<template>
  <div class="content">
    <layoutContainer size="large">
      <div class="main-content">
        <div class="nav">
          <div class="section_3">
            <!-- navigation -->
            <div class="group_1 " @mouseleave="activeIndex = ''">
              <div
                class="section_30"
                :class="activeIndex === itemKey ? ' active-hover-bg' : ''"
                v-for="(item, itemKey) in querySiteBusinessList"
                :key="itemKey"
                @mouseenter="activeIndex = itemKey"
              >
                <img class="label_1" referrerpolicy="no-referrer" :src="item.iconUrl" />
                <span class="text_10" :class="{'active-hover-text': item.linkUrl}" @click="toNavPage(item.linkUrl)">{{
                  item.name
                }}</span>
                <span
                  class="text_11"
                  :class="{'active-hover-text': ops.linkUrl}"
                  v-for="(ops, opskey) in item.hotBusinessList"
                  :key="opskey"
                  @click="toNavPage(ops.linkUrl)"
                  >{{ ops.name }}</span
                >
                <span class="space-full"></span>
                <img
                  class="thumbnail_1"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge4b4563645ecc8b0a44e5c6687f42baf205dbd5556bfb8108b121bcb40626c3e"
                />

                <div class="group_14" v-if="activeIndex === itemKey">
                  <div class="group_14-content scroll_bar" v-bar>
                    <div class="scroll-container">
                      <span
                        class="text_36"
                        :class="{'active-hover-text': item.linkUrl}"
                        @click="toNavPage(item.linkUrl)"
                        >{{ item.name }}</span
                      >
                      <div class="text-wrapper_85 " v-for="(group, groupKey) in item.businessGroupList" :key="groupKey">
                        <span
                          class="text_37"
                          :class="{'active-hover-text': group.linkUrl}"
                          @click="toNavPage(group.linkUrl)"
                          >{{ group.name }}</span
                        >
                        <div
                          class="box_40"
                          :class="{bd_borrom: group.children.length && groupKey !== item.businessGroupList.length - 1}"
                        >
                          <span
                            class="text_40"
                            :class="{'active-hover-text': classify.linkUrl}"
                            v-for="(classify, classifyKey) in group.children"
                            :key="classifyKey"
                            @click="toNavPage(classify.linkUrl)"
                            >{{ classify.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- banner -->
            <div class="quanqing-swiper-container" v-if="querySlidePicList.length">
              <QuanqingSwiper :querySlidePicList="querySlidePicList" currentIndex="1"></QuanqingSwiper>
            </div>
            <!-- user info -->
            <div class="group_16 ">
              <!-- hasMember -->
              <img
                class="box_5 "
                @click="toNavPage('/my', true)"
                :src="
                  userBusinessInfo.userHeadPicUrl
                    ? userBusinessInfo.userHeadPicUrl
                    : 'https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/9c3223a7b19f4d4b90f528b5a8b378a4_mergeImage.png'
                "
              />
              <!-- <div class="block_23" v-if="!userBusinessInfo.userId"> -->
              <div class="block_23">
                <p
                  v-if="userBusinessInfo.userId && UserInfo.phonenumber"
                  class="user_name"
                  @click="toNavPage('/my', true)"
                >
                  <span>{{ UserInfo.phonenumber }}</span>
                  <img class="vip-icon" v-if="userBusinessInfo.hasMember" src="/img/huiyuanvip.png" alt="v" />
                </p>
                <el-button v-if="!userBusinessInfo.userId" class="button_50" round @click="toNavPage('/login')"
                  >登录</el-button
                >
                <el-button v-if="!userBusinessInfo.userId" class="button_51" round @click="toNavPage('/login')"
                  >注册</el-button
                >
              </div>
              <div class="block_24">
                <div class="group_17 "></div>
                <span class="text_52">我是服务方</span>
              </div>
              <div class="box_8">
                <span class="text_53" @click="toNavPage('/product?publishType=1', true)"
                  >产品({{ userBusinessInfo.serviceCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage('/serve?publishType=1', true)"
                  >服务({{ userBusinessInfo.serviceCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage('/techVideo?isMyPublish=1', true)"
                  >知识({{ userBusinessInfo.knowledgeCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage(`/companyDetail/${userBusinessInfo.companyId}`, true)"
                  >公司页</span
                >
                <span class="text_53" @click="toNavPage(`/teams/detail/${userBusinessInfo.teamId}`, true)">团队页</span>
                <span class="text_53" @click="toNavPage(`/personal/detail/${userBusinessInfo.employeeId}`, true)"
                  >个人页</span
                >
              </div>
              <div class="block_24">
                <div class="group_17 "></div>
                <span class="text_52">我是用户</span>
              </div>
              <div class="box_8">
                <span class="text_53" @click="toNavPage('/project?publishType=1', true)"
                  >项目({{ userBusinessInfo.serviceCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage('/techVideo?publishType=1', true)"
                  >知识({{ userBusinessInfo.knowledgeBuyCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage('/orderList', true)"
                  >订单({{ userBusinessInfo.orderBuyCount || 0 }})</span
                >
                <span class="text_53" @click="toNavPage('/my', true)">个人中心</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 项目 -->
        <div class="project-wrap">
          <div>
            <ExampleHeader
              title="项目"
              :number="projectListTotal"
              :description="userBusinessInfo.projectIntroduction"
              @moreAction="$router.push('/project')"
            >
            </ExampleHeader>
            <div class="projectList-item-wrapper">
              <ProjectListItem
                v-for="(item, key) in projectList"
                :key="key"
                :info="item"
                :tagName="
                  businessList.softwareDevelopmentList[item.businessType - 1] &&
                    businessList.softwareDevelopmentList[item.businessType - 1].name
                "
                @moreAction="$router.push(`/projectDetail?id=${item.id}`)"
              ></ProjectListItem>
            </div>
          </div>
        </div>

        <!-- 产品 + 服务-->
        <div class="plus-wrap">
          <div class="plus-box">
            <!--产品  -->
            <div class="product-wrap">
              <ExampleHeader
                size="mini"
                title="产品"
                :number="productListTotal"
                :description="userBusinessInfo.productIntroduction"
                @moreAction="$router.push('/product')"
              >
              </ExampleHeader>
              <div class="productList-item-wrapper">
                <ProductListItem v-for="(item, key) in productList" :key="key" :info="item"></ProductListItem>
              </div>
            </div>
            <!--服务  -->
            <div class="serve-wrap">
              <ExampleHeader
                size="mini"
                title="服务"
                :number="serveListTotal"
                :description="userBusinessInfo.serviceIntroduction"
                @moreAction="$router.push('/serve')"
              />
              <div class="serve-item-wrapper">
                <ServeListItem v-for="(item, key) in serveList" :key="key" :info="item"></ServeListItem>
              </div>
            </div>
          </div>
        </div>

        <!-- 标准包 -->
        <div class="standard-wrap">
          <div>
            <ExampleHeader
              title="标准包"
              :number="standardPackageListTotal"
              :description="userBusinessInfo.standardPackageIntroduction"
              @moreAction="$router.push('/quanqingStandard')"
            >
            </ExampleHeader>
            <div class="standard-item-wrapper">
              <StandardPackageListItem
                v-for="(item, key) in standardPackageList"
                :key="key"
                :info="item"
                @clickCallBack="$router.push(`/quanqingStandard?packageId=${item.packageId}`)"
              >
              </StandardPackageListItem>
            </div>
          </div>
        </div>

        <!-- 技术视频 -->
        <div class="subject-wrap">
          <div>
            <ExampleHeader
              title="技术视频"
              :number="subjectListTotal"
              :description="userBusinessInfo.knowledgeIntroduction"
              @moreAction="$router.push('/techVideo')"
            >
            </ExampleHeader>
            <div class="subject-item-wrapper">
              <SubjectListItem v-for="(info, key) in subjectList" :key="key" :info="info"></SubjectListItem>
            </div>
          </div>
        </div>

        <div class="company-wrap">
          <CommonContainer
            title="公司"
            :total="companyListTotal"
            @moreAction="$router.push('/company')"
            :introduction="userBusinessInfo.companyIntroduction"
          >
            <companyListItem v-for="(item, i) in companyList" :key="`${i}${Math.random()}`" :item="item" size="mini" />
          </CommonContainer>
        </div>
        <div class="persion-wrap">
          <CommonContainer
            :title="`个人`"
            @moreAction="$router.push('/personal')"
            :introduction="userBusinessInfo.employeeIntroduction"
            :total="personListTotal"
          >
            <persionListItem v-for="(item, i) in personList" :key="`${i}${Math.random()}`" :item="item" size="mini" />
          </CommonContainer>
        </div>

        <div class="team-wrap">
          <CommonContainer
            :title="`团队`"
            @moreAction="$router.push('/teams')"
            :notFull="teamsLsit.length < 4"
            :introduction="userBusinessInfo.teamIntroduction"
            :total="teamsLsitTotal"
          >
            <teamListItem v-for="(item, i) in teamsLsit" :key="`${i}${Math.random()}`" :item="item" size="mini" />
          </CommonContainer>
        </div>
      </div>
    </layoutContainer>

    <el-dialog :visible.sync="DialogVisible" width="1000px">
      <marketing :type="1" :visible.sync="DialogVisible"></marketing>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getCompanyListApi} from '../api/company'
import {getEmployeeListApi} from '../api/personal'
import {getTeamListApi} from '../api/team'
import {projectList} from '../api/project'
import {productListApi} from '../api/product'
import {serveListApi} from '../api/serve'
import {subjectList} from '../api/subject'
import {getStandardQueryWeightPackageListApi} from '../api/standard'
import {getUserBusinessInfoApi, getQuerySiteBusinessListApi, getQuerySlidePicListApi} from '../api/home'
import marketing from '../components/marketing'
import QuanqingSwiper from '../components/swiper/index.vue'
import ExampleHeader from '../components/home/exampleHeader.vue'
import ProjectListItem from '../components/home/ProjectListItem.vue'
import ProductListItem from '../components/home/ProductListItem.vue'
import ServeListItem from '../components/home/ServeListItem.vue'
import StandardPackageListItem from '../components/home/StandardPackageListItem.vue'
import SubjectListItem from '../components/home/SubjectListItem.vue'
import CommonContainer from '../components/home/CommonContainer.vue'
import {getBusinessListApi} from '@/api/index'

const baseParams = {
  pageNum: 1,
  pageSize: 4,
  useWeight: true
}

const baseOldParams3 = {page: 1, size: 3, useWeight: true}
const baseOldParams = {page: 1, size: 4, useWeight: true}
export default {
  components: {
    marketing,
    ExampleHeader,
    QuanqingSwiper,
    ProjectListItem,
    ProductListItem,
    ServeListItem,
    StandardPackageListItem,
    SubjectListItem,
    CommonContainer
  },
  computed: {
    ...mapState(['UserInfo'])
  },
  data() {
    return {
      DialogVisible: false,
      userBusinessInfo: {},
      querySiteBusinessList: [],
      querySlidePicList: [],
      activeIndex: '',

      companyList: [],
      companyListTotal: '',
      teamsLsit: [],
      teamsLsitTotal: '',
      personList: [],
      personListTotal: '',
      projectList: [],
      projectListTotal: '',
      productList: [],
      productListTotal: '',
      serveList: [],
      serveListTotal: '',
      standardPackageList: [],
      standardPackageListTotal: '',
      subjectList: [],
      subjectListTotal: '',
      businessList: [{name: ''}]
    }
  },
  created() {
    getBusinessListApi().then(res => {
      this.businessList = res.data
      this.getCompanyList()
      this.getTeamList()
      this.getEmployeeList()
      this.getUserBusinessInfo()
      this.getQuerySiteBusinessList()
      this.getQuerySlidePicList()
      this.getProjectList()
      this.getProductList()
      this.getServeList()
      this.getStandardQueryWeightPackageList()
      this.getSubjectList()
    })
  },
  methods: {
    getProjectList() {
      projectList(baseOldParams).then(res => {
        console.log('项目列表', JSON.stringify(res, null, 2))
        this.projectList = [...res?.data?.records]
        this.projectListTotal = res?.data?.total || 0
      })
    },

    getProductList() {
      productListApi(baseOldParams3).then(res => {
        console.log('产品列表', JSON.stringify(res, null, 2))
        this.productList = [...res?.data?.records] || []
        this.productListTotal = res?.data?.total || 0
      })
    },
    getServeList() {
      serveListApi(baseOldParams3).then(res => {
        console.log('服务列表', JSON.stringify(res, null, 2))
        this.serveList = [...res?.data.records] || []
        this.serveListTotal = res?.data?.total || 0
      })
    },
    getStandardQueryWeightPackageList() {
      getStandardQueryWeightPackageListApi(baseParams).then(res => {
        console.log('标准包列表', JSON.stringify(res, null, 2))
        this.standardPackageList = [...res?.data?.records] || []
        this.standardPackageListTotal = res?.data?.total || 0
      })
    },
    getSubjectList() {
      subjectList({...baseParams, order: 3}).then(res => {
        console.log('技术视频列表', JSON.stringify(res, null, 2))
        this.subjectList = [...res?.data?.records] || []
        this.subjectListTotal = res?.data?.total || 0
      })
    },
    getCompanyList() {
      getCompanyListApi(baseOldParams).then(res => {
        this.companyList = [...(res?.data?.records || [])]
        this.companyListTotal = res?.data?.total || 0
      })
    },

    getTeamList() {
      getTeamListApi(baseOldParams).then(res => {
        this.teamsLsit = [...(res?.data?.records || [])]
        this.teamsLsitTotal = res?.data?.total || 0
      })
    },
    getEmployeeList() {
      getEmployeeListApi(baseOldParams).then(res => {
        this.personList = [...(res?.data?.records || [])]
        this.personListTotal = res?.data?.total || 0
      })
    },

    getUserBusinessInfo() {
      getUserBusinessInfoApi().then(res => {
        console.log('用户信息', JSON.stringify(res, null, 2))
        this.userBusinessInfo = {...(res?.data || {})}
      })
    },
    getQuerySiteBusinessList() {
      getQuerySiteBusinessListApi().then(res => {
        this.querySiteBusinessList = [...(res?.data || [])]
      })
    },
    getQuerySlidePicList() {
      getQuerySlidePicListApi().then(res => {
        console.log('轮播图', JSON.stringify(res, null, 2))
        this.querySlidePicList = [...(res?.data || [])]
      })
    },

    toNavPage(path, needLogin) {
      if (!path) {
        return
      }
      if (needLogin && !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      this.$router.push(path)
    }
  }
}
</script>

<style src="./index.scss" lang="scss" scoped></style>

<style lang="scss" scoped>
.content {
  width: 100%;

  /deep/ .el-dialog {
    border-radius: 10px;
  }

  .center-box {
    margin-top: 0;
  }
}
</style>
