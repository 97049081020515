import request from '@/utils/request'
/**
 * 首页相关
 */
// 查询首页用户业务信息
export const getUserBusinessInfoApi = () =>
  request({
    url: '/index/getUserBusinessInfo',
    method: 'get'
  })

// 查询全站类目列表
export const getQuerySiteBusinessListApi = () =>
  request({
    url: '/index/querySiteBusinessList',
    method: 'get'
  })


// 查询首页轮播图列表
export const getQuerySlidePicListApi = () =>
  request({
    url: '/index/querySlidePicList',
    method: 'get'
  })


