<template>
  <div>
    <div class="list-items_1 flex-col" @click="$router.push(`/techVideoDetail?id=${info.id}`)">
      <div class="image-text_54 flex-col justify-between">
        <img class="image-box" v-lazy="info.backgroundPicUrl" alt="" />
        <span class="text_134" v-if="info.hotNum > 0">热门</span>
        <span class="text-group_19 ellipsis-line2" :style="handleTitleColor(info.backgroundPicUrl)">{{
          info.name
        }}</span>
      </div>
      <div class="box_56">
        <div class="text_135 ellipsis-line2">{{ info.introduction }}</div>
        <div class="image-text_56 flex-row justify-between">
          <img class="thumbnail_15" referrerpolicy="no-referrer" v-lazy="'img/icon.png'" />
          <span class="text-group_21">{{ info.employeeName }}</span>
          <img class="thumbnail_16" referrerpolicy="no-referrer" v-lazy="'/img/时间.png'" />
          <span class="text_136">{{ computedTime(info.videoLength) }}</span>
        </div>
      </div>
      <div class="box_57 flex-row justify-between">
        <span v-if="info.price" class="text_137">￥{{ setPriceNum(info.price) }}</span>
        <!-- <div class="text-wrapper_35 flex-col">
                    <span class="text_138">购买</span>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {setPriceNum} from '@/utils/index'

export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  name: 'SubjectListItem',
  methods: {
    computedTime(time) {
      console.log('time: ', time)
      let hour = 0
      let minute = 0
      hour = Math.floor(time / 60)
      minute = Math.ceil(time - hour * 60)

      return hour ? `${hour}小时${minute > 0 ? `${minute}分钟` : ''}` : `${minute}分钟`
    },
    setPriceNum,
    handleTitleColor(url) {
      const imgName = url.split('/')[url.split('/').length - 1]
      let color = ''
      switch (imgName) {
        case '1.png':
          color = 'rgba(73, 93, 173, 1)'
          break
        case '2.png':
          color = 'rgba(255, 104, 31, 1)'
          break
        case '3.png':
          color = 'rgba(92, 43, 255, 1)'
          break
        default:
          color = '#fff'
          break
      }
      return {
        color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-items_1 {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  width: 285px;
  height: 350px;
  margin-right: 20px;
  cursor: pointer;

  .image-text_54 {
    width: 285px;
    height: 160px;
    margin-top: -1px;
    position: relative;
    overflow: hidden;
  }

  .image-box {
    max-width: 285px;
    max-height: 160px;
  }

  .text_134 {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: #f74819;
    border-radius: 8px 30px 30px 8px;
    padding-left: 8px;
    padding-right: 10px;
  }

  .text-group_19 {
    position: absolute;
    left: 15px;
    width: 165px;
    top: 48px;
    font-size: 26px;
    font-weight: 800;
    font-family: PangMenZhengDao;
    color: #ffffff;
    line-height: 30px;
  }

  .box_56 {
    width: 156px;
    margin: 12px 0 0 16px;

    .thumbnail_14 {
      width: 18px;
      height: 18px;
      margin-top: 3px;
    }

    .text_135 {
      width: 250px;
      height: 50px;
      overflow-wrap: break-word;
      color: #666666;
      font-size: 18px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      /* white-space: nowrap; */
      line-height: 25px;
      margin-left: 4px;
    }

    .image-text_56 {
      width: 68px;
      height: 25px;
      margin-top: 16px;

      .thumbnail_15 {
        width: 17px;
        height: 17px;
        margin-top: 4px;
      }

      .text-group_21 {
        width: 46px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }
    }

    .thumbnail_16 {
      width: 18px;
      height: 18px;
      margin: 3px 0 0 16px;
    }

    .text_136 {
      width: 46px;
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
      margin-left: 4px;
    }
  }

  .box_57 {
    width: 253px;
    height: 33px;
    margin: 16px 0 16px 16px;
    display: flex;

    .text_137 {
      flex: 1;
      height: 33px;
      overflow-wrap: break-word;
      color: rgba(255, 114, 76, 1);
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
    }

    .text-wrapper_35 {
      background-color: rgba(255, 114, 76, 1);
      border-radius: 15px;
      height: 30px;
      margin-top: 2px;
      width: 64px;

      .text_138 {
        width: 32px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        letter-spacing: -0.2000001072883606px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 4px 0 0 17px;
      }
    }
  }
}

.list-items_1:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.5);
}
</style>
