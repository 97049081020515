<template>
  <div class="standard-box" @click="$emit('clickCallBack')">
    <img v-lazy="info.logoPicUrl" alt="logo" />
    <div class="standard-right">
      <div class="title">{{ info.packageName }}</div>
      <div class="standard_total">
        <span class="number">{{ info.standardTotal }}条标准</span>
      </div>
      <div class="description ellipsis-line2">{{ info.introduction }}</div>
      <span class="total">¥{{ setPriceNum(info.packagePrice) }}</span>
    </div>
  </div>
</template>

<script>
import {setPriceNum} from '@/utils/index'

export default {
  props: ['size', 'info'],
  name: 'StandardPackageListItem',
  methods: {
    setPriceNum
  }
}
</script>

<style lang="scss" scoped>
.standard-box {
  width: 287px;
  height: 201px;
  background: #ffffff;

  border-radius: 20px;
  padding: 20px;
  display: flex;
  cursor: pointer;

  img {
    width: 80px;
    height: 80px;
  }
}

.standard-box:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.5);
}
.standard_total {
  display: flex;
}
.standard-right {
  margin-left: 20px;
  font-family: PingFangSC-Medium, PingFang SC;

  .title {
    height: 33px;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    margin-bottom: 10px;
  }

  .number {
    display: block;
    height: 28px;
    background: #ffe4d8;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 400;
    color: #ff724c;
    line-height: 28px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .description {
    margin-top: 9px;
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
  }

  .total {
    font-size: 16px;
    font-weight: 600;
    color: #ff724c;
    line-height: 22px;
  }
}
</style>
