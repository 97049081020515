<template>
  <!-- 轮播图 -->
  <div class="swiper-container backgroundfff" @mouseenter="stopSwiper" @mouseleave="startSwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, key) in querySlidePicList" :key="key">
        <img :src="item.backgroundPicUrl" />
        <div class="text-area-bg"></div>
        <div class="text-area">
          <p class="title">{{ item.title }}</p>
          <p class="description">{{ item.description }}</p>
          <el-button v-if="item.url" class="button" @click="toNavPage(item.url)">查看详情</el-button>
        </div>
      </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
// 引入swiper插件的js
import Swiper from 'swiper'
export default {
  name: 'index',
  data() {
    return {
      swiper: null
      //         querySlidePicList: [
      //             // {
      //             //     "backgroundPicUrl": "http://81.70.36.99:8002/download/imgDownload/202307/829b667b06064faa9962fa9e45bc56c2.jpg",
      //             //     "description": "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
      //             //     "slideId": 5,
      //             //     "slidePicId": 5,
      //             //     "title": "标题3",
      //             //     "url": ""
      //             // },
      //             // {
      //             //     "backgroundPicUrl": "http://81.70.36.99:8002/download/imgDownload/202307/7ade8787170842d48469b14eb62d969e.jpg",
      //             //     "description": "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
      //             //     "slideId": 3,
      //             //     "slidePicId": 3,
      //             //     "title": "标题2",
      //             //     "url": "/45356456"
      //             // },
      //             // {
      //             //     "backgroundPicUrl": "http://81.70.36.99:8002/download/imgDownload/202307/0c20dbd001ee4e64b5ef30a36c2c6491.jpg",
      //             //     "description": "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
      //             //     "slideId": 2,
      //             //     "slidePicId": 2,
      //             //     "title": "标题1",
      //             //     "url": "/aaaaa"
      //             // }
      //         ],
    }
  },
  props: {
    querySlidePicList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  created() {},
  mounted() {
    this.myswiper()
    const pagination = document.getElementsByClassName('swiper-pagination-bullet')
    pagination.forEach((element, index) => {
      const text = document.createTextNode(index + 1)
      element.appendChild(text)
    })
  },
  methods: {
    myswiper: function() {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          clickable: true,
          el: '.swiper-pagination'
        },
        loop: true,
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      })
    },
    toNavPage(path) {
      if (!path) {
        return
      }
      this.$router.push(path)
    },
    startSwiper() {
      this.swiper.autoplay.start()
    },
    stopSwiper() {
      this.swiper.autoplay.stop()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 引入swipercss */
@import url('../../../node_modules/swiper/css/swiper.css');

.swiper-container {
  width: 555px;
  height: 424px;
  margin-left: 15px;
  border-radius: 15px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.text-area-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 150;
}

.text-area {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding-top: 55px;
  padding-left: 41px;
  text-align: left;
  z-index: 200;

  .title {
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }

  .description {
    margin-top: 16px;
    width: 466px;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 37px;
  }

  .button {
    margin-top: 19px;
    width: 126px;
    height: 44px;
    background: #5ad3cd;
    border-radius: 22px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border: none;
  }
}

/deep/ .swiper-pagination-bullets {
  bottom: 20px;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  line-height: 25px;
}

/deep/ .swiper-pagination-bullet {
  width: 50px;
  height: 26px;
  border-radius: 2px;
  background: #333333;
  opacity: 0.4;
  text-align: center;
  color: #ffffff;
}

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
  color: #333333;
}
</style>
